import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Avatar } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import useAuthHook from "../../Hooks/useAuthHook";

const pages = ["buy", "sell", "news", "about"];
// const settings = ["profile"];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [token, setToken] = React.useState();
  // const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const { user } = useAuthHook();

  let token = localStorage.getItem("access_token");
  console.log(token, "tokenn");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlePage = (page) => {
    switch (page) {
      case "buy":
        return "dealerships";
      case "sell":
        return "how-to-list-with-checkdr";
      default:
        return page;
    }
  };
  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };
  const isLargeScreen = useMediaQuery("(max-width: 525px)");
  return (
    <AppBar
      position="relative"
      sx={{
        bgcolor: "white",
        boxShadow: "none",
        borderBottom: "0.1px solid #F4F4F4",
        width: "100%",
        paddingInline: "20px",
      }}
    >
      <Toolbar disableGutters>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="https://checkdrive.co/"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <img src="/logo.svg" alt="logo" style={{ width: "180px" }} />
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon sx={{ color: "black" }} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                <Typography
                  sx={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "600",
                  }}
                  textAlign="center"
                  component="a"
                  href={`https://checkdrive.co/${handlePage(page)}`}
                >
                  {page}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="https://checkdrive.co/"
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          {isLargeScreen ? (
            <img src="/Check.png" alt="check_logo" style={{ width: "34px" }} />
          ) : (
            <img src="/logo.svg" alt="logo" style={{ width: "170px" }} />
          )}
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            marginLeft: "24px",
          }}
        >
          {pages.map((page) => (
            <Typography
              key={page}
              onClick={handleCloseNavMenu}
              className="hover-underline-animation"
              component="a"
              href={`https://checkdrive.co/${handlePage(page)}`}
              sx={{
                ":hover": { backgroundColor: "transparent" },
                textDecoration: "none",
                cursor: "pointer",
                color: "black",
                marginRight: "2em",
                textTransform: "uppercase",
                // fontWeight: 500,
              }}
            >
              {page}
            </Typography>
          ))}
        </Box>
        {/* 
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Profile" sx={{bgcolor:"#87AC8D",color:"white"}} src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        {token ? (
          <div>
            {user.profilePic ? (
              <Avatar size="small" src={user.profilePic} alt={user.name} />
            ) : (
              <AccountCircle />
            )}
          </div>
        ) : (
          <Box gap={"6px"} display="flex">
            <Button
              disableRipple
              sx={{
                color: "white",
                ":hover": {
                  bgcolor: "transparent",
                  border: "1px solid #87AC8D",
                  color: "#87AC8D",
                },
                bgcolor: "#87AC8D",
                width: { xs: "90px", sm: "100px" },
                borderRadius: "18px",
              }}
              onClick={() => navigate("/auth/login")}
            >
              Sign In
            </Button>

            <Button
              onClick={() => navigate("/auth/login")}
              disableRipple
              sx={{
                bgcolor: "transparent",
                border: "1px solid #87AC8D",
                color: "#87AC8D",
                ":hover": {},
                width: { xs: "90px", sm: "100px" },
                borderRadius: "18px",
              }}
            >
              Sign Up
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;
// bgcolor:"#87AC8D",color:"white"
